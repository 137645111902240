@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url(bootstrap.min.css);
@import url(font-awesome.min.css);
@import url(owl.carousel.css);
@font-face {
    font-family: 'maven_proregular';
    src: url('../MediaCss/fonts/mavenpro-regular-webfont.woff2') format('woff2'), url('../MediaCss/fonts/mavenpro-regular-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dinokoregular';
    src: url('../MediaCss/fonts/dinoko-regular-webfont.woff2') format('woff2'), url('../MediaCss/fonts/dinoko-regular-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cronus_roundregular';
    src: url('../MediaCss/fonts/cronus_round-webfont.woff2') format('woff2'), url('../MediaCss/fonts/cronus_round-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'maven_proregular !important';
    font-size: 18px !important;
    color: #000;
    line-height: 28px;
    font-weight: 400;
}

.dispN {
    display: none
}

li.btnbg {
    width: 150px;
    height: 100px;
    transform: skew(20deg);
    background: red;
}

.bg1 {
    background-image: url('mediaimages/bg1_New.jpg');
    background-repeat: no-repeat;
    background-position: left center;
    height: auto;
    background-position: -101px 16px;
    width: auto;
    z-index: -1;
}


/*.title {
    font-size: 60px;
    color: #58595b;
    margin: 0px;
    padding: 30px 0px 22px 0px;
    text-shadow: 3px 0 #fff, 0 4px #fff, 4px 0 #58595b, 0 -1px #fff;
    letter-spacing: 1.3px;
    font-family: 'dinokoregular';
}*/


/*.title.gallery { color:#ffa7db}*/

.titlevidd {
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(212, 208, 21, 1) 50%, rgba(215, 37, 37, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 42px;
    font-weight: 700;
    font-family: 'Fredoka One', cursive;
    letter-spacing: 1px;
    text-align: left;
}

.btmmgn {
    margin: 0px 0px 2% 0
}


/*.textwidth {*/


/*width: 40%;*/


/*display: inline-block;
}*/

#pentagon {
    position: relative;
    width: 122px;
    box-sizing: content-box;
    border-width: 50px 18px 0;
    border-style: solid;
    border-color: #15978b transparent;
    border-radius: 10px 10px 10px 10px
}

.readmore {
    position: relative;
    z-index: 99;
    /* margin-top: -13px; */
    top: -39px;
    color: #fff;
    padding-left: 18px;
}

.txtround {
    float: right;
    margin-top: 4%;
    position: relative;
    left: 64px;
    z-index: -1;
    padding: 24px 90px 24px 25px;
    background: #ffe293;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #fff, 0 4px #ffe293, 4px 0 #ffe293, 0 -1px #ffe293;
    color: #58595b;
}

.asstxt {
    float: left;
    margin-top: 12px;
    position: relative;
    left: 34px;
    width: 60%;
}

.asstxtnew {
    float: right;
    margin-top: 0px;
    position: relative;
    /*left: 35px;
    width: 61%;*/
}

.cons-txtround {
    /* float: left; */
    /* margin-top: 0%; */
    position: absolute;
    z-index: -1;
    padding: 24px 67px 24px 62px;
    background: #add6f2;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #add6f2, 0 4px #fff, 4px 0 #fff, 0 -1px #565a5d;
    color: #58595b;
    /* top: 0; */
    bottom: 64%;
    left: 34%;
}


/*.training-txtround {*/


/* float: left; */


/* margin-top: 0%; */


/*position: absolute;
    z-index: -1;
    padding: 24px 67px 24px 62px;
    background: #f7acbc;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #f7acbc, 0 4px #fff, 4px 0 #fff, 0 -1px #565a5d;
    color: #58595b;*/


/* top: 0; */


/*bottom: 73%;
    left: 34%;
}*/

.constxt {
    /* float: left; */
    margin-top: 12px;
    position: absolute;
    top: 40%;
    left: 40%;
}


/*.training-constxt {*/


/* float: left; */


/*margin-top: 12px;
    position: absolute;
    top: 30%;
    left: 40%;
}*/

.bg {
    background: #f7acbc !important
}

.traininground {
    float: right;
    margin-top: 4%;
    position: relative;
    left: 64px;
    z-index: -1;
    padding: 24px 90px 24px 25px;
    background: #b7da9b;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #fff, 0 4px #b7da9b, 4px 0 #b7da9b, 0 -1px #b7da9b;
    color: #58595b;
}

.remediabg {
    background: #b7da9b !important;
}

.remediaround {
    float: right;
    margin-top: 4%;
    position: relative;
    left: 64px;
    z-index: -1;
    padding: 24px 48px 24px 25px;
    background: #b7da9b;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #fff, 0 4px #b7da9b, 4px 0 #b7da9b, 0 -1px #b7da9b;
    color: #58595b;
}

.mt10 {
    margin-top: 4%;
}

.homeicon {
    font-size: 49px;
    color: #ffa7db;
}

.location {
    font-size: 32px;
    text-align: left;
    color: #fff;
    /* padding-left: 10px; */
    line-height: 49px;
    font-family: 'dinokoregular';
    text-shadow: 1px 1px 1px #000;
    margin-left: 30%;
}

.contactbg {
    background: url('mediaimages/contact-bg1.png') no-repeat;
    padding: 15px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    min-height: 305px
}

.contactbg p,
.contactbg p a {
    color: #000;
}

.phonclor {
    color: #ece5a1;
}

.emailclr {
    color: #6b8559;
}

.hourbg {
    background: url('mediaimages/contact-bg2.png') no-repeat;
    padding: 15px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    min-height: 305px;
}

.hourbg ul {
    margin: 0px;
    padding: 0px;
}

.hourbg li {
    margin: 0px 0px 0px 55px;
    padding: 4px 0;
    list-style: none;
    color: #000;
    font-size: 18px;
    text-align: left;
}

.cronusfont {
    font-family: 'cronus_roundregular' ;
    color: #000000;
    font-size: 34px;
    text-align: left;
}

#parallelogram1 {
    width: 150px;
    height: 50px;
    transform: skew(20deg);
    background: red;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    border-radius: 10px;
}

#pentagon1 {
    position: relative;
    width: 54px;
    box-sizing: content-box;
    border-width: 50px 18px 0;
    border-style: solid;
    border-color: red transparent;
}

#pentagon1:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: -85px;
    left: -18px;
    border-width: 0 45px 35px;
    border-style: solid;
    border-color: transparent transparent red;
}

.homeBtn {
    background: url("mediaimages/home-btn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.dashboardBtn {
    background: url("mediaimages/gallery-brn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.aboutBtn {
    background-image: url("mediaimages/about-btn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.servicesBtn {
    background-image: url("mediaimages/services-btn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.galleryBtn {
    background-image: url("mediaimages/gallery-brn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.contactUsBtn {
    background-image: url("mediaimages/contactus-brn-new.png");
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 44px;
    width: 165px;
    font-family: 'cronus_roundregular';
    color: #000;
    font-size: 18px;
    cursor: pointer;
}

.login-btn {
    background-image: url("mediaimages/register-img.png");
    background-position: center right;
    background-color: #16978c;
    color: #ffbf27;
    padding: 0px;
    background-repeat: no-repeat;
    border-radius: 10px;
    /* font-size: 19px !important; */
    font-family: 'cronus_roundregular';
    margin-left: 16px;
}

.navbar-default .navbar-nav li.login-btn a {
    color: #ffbf27;
    text-align: center;
    font-size: 24px;
    padding: 10px 27px 10px 16px;
    font-family: 'cronus_roundregular';
    font-weight: 700;
}

.regi-btn {
    background-image: url("mediaimages/login-img.png");
    background-position: center left;
    background-color: #d61b69;
    color: #ffbf27;
    padding: 0px;
    background-repeat: no-repeat;
    border-radius: 10px;
    /* font-size: 19px !important; */
    font-family: 'cronus_roundregular';
}

.navbar-default .navbar-nav li.regi-btn a {
    color: #ffbf27;
    text-align: center;
    font-size: 24px;
    padding: 10px 15px 10px 33px;
    font-family: 'cronus_roundregular';
    font-weight: 700;
}

.navbar-default {
    background-color: transparent;
    border-color: transparent;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #000;
    background-color: transparent;
}

.navbar-default .navbar-nav>li>a {
    color: #000;
    text-align: center;
    font-size: 28px;
}

.navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-top: -4px;
    position: relative;
    margin-bottom: 10px;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 99;
}

.doctorbg {
    /* background-image: url("mediaimages/who-we-are.png"); */
    background-position: top right;
    background-repeat: no-repeat;
}

.servicesbg {
    /* background-image: url("mediaimages/services-bg.png"); */
    background-position: left left;
    background-repeat: no-repeat;
}

.servicesbg h4 {
    font-size: 28px;
    text-align: center;
    font-family: 'cronus_roundregular';
    color: #000;
}

.trainingbackground {
    background-image: url("mediaimages/training-bg.png");
    background-position: bottom right;
    background-repeat: no-repeat;
}

.trainingbackgroundleft {
    background-image: url("mediaimages/training-bg.png");
    background-position: bottom left;
    background-repeat: no-repeat;
}

.readmorebrn {
    background-image: url("mediaimages/readmorebrn.png");
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    width: 145px;
    height: 52px;
    padding-top: 12px;
    cursor: pointer;
}

.readmorebrn a {
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.readmorebrn a:hover {
    color: #fff;
    font-size: 20px;
    text-align: center;
    text-decoration: none
}

.whoweare {
    margin: 0px;
    padding: 0px;
}

.greenbdr {
    border-bottom: 10px solid #6ca945;
    z-index: 99;
    position: relative;
}

.whowearebg {
    background: url("mediaimages/whowebg.png");
    background-repeat: no-repeat;
    background-position: right center;
}

.navbar {
    position: relative;
    min-height: 22px;
    margin-bottom: 0px;
    border: 1px solid transparent;
}


/*.accordion {
  max-width: 500px;
  margin: 0 auto;
}*/

.accordion__title {
    font-family: 'industry', sans-serif;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.125em;
}

.accordion__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.accordion__item {
    border-bottom: 1px solid #000;
    visibility: hidden;
}

.accordion__item:last-child {
    border-bottom: 0;
}

.accordion__item.is-active .accordion__itemTitleWrap::after {
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
}

.accordion__item.is-active .accordion__itemIconWrap {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.accordion__itemTitleWrap {
    display: flex;
    height: 67px;
    align-items: center;
    padding: 0 1em;
    color: #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.accordion__itemTitleWrap::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: #3bade3;
    background: linear-gradient(45deg, #3bade3 0%, #576fe6 25%, #9844b7 51%, #ff357f 100%);
    z-index: 1;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}

.accordion__itemTitleWrap.is-active::after,
.accordion__itemTitleWrap:hover::after {
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
}

.accordion__itemIconWrap {
    width: 1.25em;
    height: 1.25em;
    margin-left: auto;
    position: relative;
    z-index: 10;
}

.accordion__itemTitle {
    z-index: 10;
    padding: 16px 3px;
    padding: 10px 0;
}

.accordion__itemContent {
    font-size: 0.875em;
    height: 0;
    overflow: hidden;
    background-color: #fff;
    padding: 0 1.25em;
}

.accordion__itemContent p {
    margin: 2em 0;
}

.min-hg {
    min-height: 500px !important;
}

.location.marl0 {
    margin-left: 0px;
}

.moreText {
    /* Display nothing for the element */
    display: none;
}

.scroll-top-wrapper.show {
    visibility: visible;
    cursor: pointer;
}

.scroll-top-wrapper {
    visibility: hidden;
    text-align: center;
    color: #eeeeee;
    line-height: 40px;
}

.scroll-top-wrapper.show {
    visibility: visible;
    cursor: pointer;
    opacity: 1.0;
}

.scroll-top-wrapper {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    z-index: 99999999;
    background-color: #E3801B;
    color: #eeeeee;
    width: 40px;
    height: 40px;
    line-height: 40px;
    right: 40px;
    bottom: 40px;
    padding-top: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.btnbg {
    background: none;
    border: none;
}

.btnbg:focus,
.btnbg:active {
    outline: none !important
}

.cronusfontTxt {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
}


/* Mainmenu css*/

.main-menu {
    /*float: right;
  position: relative;*/
}

.main-menu .navbar-collapse {
    padding: 0px
}

.main-menu .navigation {
    margin: 0;
    position: relative;
}

.main-menu .navigation li {
    float: left;
    /* margin-left: 12px; */
    padding: 67px 0px 0px 0px;
    /* position: relative; */
    font-family: 'cronus_roundregular';
    list-style: none;
    font-size: 24px;
}

.main-menu .navigation li a {
    color: #252525;
    display: block;
    font-size: 30px;
    line-height: 30px;
    padding: 11px 0px 0px 0px;
    /* text-transform: uppercase; */
    font-family: 'cronus_roundregular';
    transition: all 500ms ease 0s;
    text-align: center;
}

.main-menu .navigation>li.dropdown>a {
    padding-right: 40px
}

.main-menu .navigation>li>ul>li {
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border-top: 1px solid #e6e6e6;
}

.main-menu .navigation>li>ul>li>a {
    background: #f7f7f7;
    color: #252525;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 15px 15px 15px 20px;
    position: relative;
    text-transform: uppercase;
    transition: all 500ms ease 0s;
}

.main-menu .navigation>li>ul>li:hover>a {
    color: #d5ac63;
    background-color: #252525;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
    font-family: 'FontAwesome';
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 15px;
    display: block;
    color: #252525;
    font-size: 14px;
    text-align: center;
    z-index: 5;
    font-weight: 400;
}

.main-menu .navigation>li>ul>li.dropdown:hover>a:after {
    color: #d5ac63;
}

.main-menu .navigation>li>ul>li>ul {
    position: absolute;
    left: 110%;
    top: 0px;
    width: 220px;
    padding: 0px;
    z-index: 100;
    border-bottom: 3px solid #d5ac63;
    border-left: 1px solid #fdfcfc;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;
}

.main-menu .navigation>li>ul li:hover ul {
    left: 100%;
    opacity: 1;
    visibility: visible;
}

.main-menu .navigation>li>ul>li ul li {
    float: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    border-top: 1px solid #e6e6e6;
}

.main-menu .navigation>li>ul>li ul li:first-child {
    border-top: none;
}

.main-menu .navigation>li>ul>li ul li a {
    background: #f7f7f7;
    color: #252525;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 15px 15px 15px 20px;
    position: relative;
    text-transform: uppercase;
    transition: all 500ms ease 0s;
}

.main-menu .navigation>li>ul>li>ul>li:hover>a {
    background-color: #252525;
    color: #d5ac63;
}

.main-menu .navbar-collapse>ul li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 34px;
    height: 30px;
    border: 1px solid #ffffff;
    /* background: url(Images/icon/submenu-icon.png) center center no-repeat; */
    background-size: 20px;
    cursor: pointer;
    z-index: 5;
    display: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    -moz-border-radius: 3px;
}

.top-bar-area {
    /* background: #bf000e; */
    padding: 8px 0 0px;
    position: absolute;
    right: 0;
    z-index: 999;
}

.top-bar-area ul {
    margin: 0px;
    padding: 0px;
}

.top-bar-area li {
    /* margin: 0px 0px 0px 10px; */
    padding: 0px 14px 0px 14px;
    list-style: none;
    display: inline;
    text-align: right;
    float: left;
    color: #fff;
    margin-right: 27px;
    font-size: 18px;
}

.top-bar-area li a {
    color: #fff;
    font-size: 22px;
    margin: 0;
    padding: 9px 16px 14px 12px;
}

.headbdr {
    border: 5px solid;
    border-image-source: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
    border-image-slice: 1;
    border-top: none;
    border-left: none;
    border-right: none
}

.menubg::before,
.menubg::before {
    position: absolute;
    content: "";
    border-left: 45px solid transparent;
    border-right: 44px solid transparent;
    border-bottom: 45px solid yellow;
    bottom: 0;
    width: 15%;
    left: 41%;
}

.menubg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
}

.ele-critia {
    /* float: left; */
    /* margin-top: 0%; */
    position: absolute;
    z-index: -1;
    padding: 24px 30px 24px 62px;
    background: #f7acbc;
    border-radius: 28px;
    font-family: 'dinokoregular';
    font-size: 49px;
    text-shadow: 3px 0 #f7acbc, 0 4px #fff, 4px 0 #fff, 0 -1px #565a5d;
    color: #58595b;
    /* top: 0; */
    bottom: 67%;
    left: 34%;
}

.training-constxt1 {
    /* float: left; */
    margin-top: 12px;
    position: absolute;
    top: 32%;
    left: 40%;
}


/*correction*/

/* .top-bar-area {
    z-index: 9999;
} */


/*NEw Changes*/

.parallelogram {
    background: url("mediaimages/contact-bg.png") no-repeat;
    padding: 15px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    min-height: 305px;
}

.parallelogram p {
    color: #fff;
}

.loginbackground {
    background-image: url(mediaimages/bg1.jpg);
    background-repeat: no-repeat;
    /* background-position: left center; */
    height: 500px;
    background-position: 51px -45px;
    width: auto;
    z-index: -1;
}

.registerbackground {
    background-image: url(mediaimages/bg1_right.jpg);
    background-repeat: no-repeat;
    /* background-position: left center; */
    background-position: -664px 36px;
    width: auto;
    z-index: -1;
}

.disc {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    line-height: 21px;
    font-family: 'maven_proregular';
}

.mavinprofot {
    font-family: 'maven_proregular';
}

.navbar {
    position: relative;
    min-height: 22px;
    margin-bottom: 0px;
    border: 1px solid transparent;
}

.mavinprofot ul {
    margin: 0px;
    padding: 0;
}

.mavinprofot li {
    margin: 0px 0px 0px 19px;
    padding: 0;
    list-style-position: outside;
}


/*mediacss*/

@media only screen and (max-width: 1024px) {
    .homeBtn {
        background: #ffbf27;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 46px;
        width: 140px;
        font-family: 'cronus_roundregular';
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .aboutBtn {
        background: #e98080;
        background-image: none;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 44px;
        width: 140px;
        font-family: 'cronus_roundregular';
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .servicesBtn {
        background: #87cde1;
        background-image: none;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 44px;
        width: 140px;
        font-family: 'cronus_roundregular';
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .galleryBtn {
        background: #8d9092;
        background-image: none;
        /* background-position: left center; */
        height: 44px;
        width: 140px;
        font-family: 'cronus_roundregular';
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .contactUsBtn {
        background: #bf80cd;
        background-image: none;
        /* background-position: left center; */
        height: 44px;
        width: 140px;
        font-family: 'cronus_roundregular';
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .location {
        font-size: 25px;
        text-align: left;
        color: #fff;
        /* padding-left: 10px; */
        line-height: 49px;
        font-family: 'dinokoregular';
        text-shadow: 1px 1px 1px #000;
        margin-left: 30%;
    }
}

@media only screen and (max-width: 991px) {
    .logo.wow.animated {
        float: left;
    }
    .homeBtn {
        background: #ffbf27;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 38px;
        width: 100px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .aboutBtn {
        background: #e98080;
        background-image: none;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 38px;
        width: 100px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .servicesBtn {
        background: #87cde1;
        background-image: none;
        background-repeat: no-repeat;
        /* background-position: left center; */
        height: 38px;
        width: 100px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .galleryBtn {
        background: #8d9092;
        background-image: none;
        /* background-position: left center; */
        height: 38px;
        width: 100px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
    .contactUsBtn {
        background: #bf80cd;
        background-image: none;
        /* background-position: left center; */
        height: 38px;
        width: 100px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
        background-image: none;
    }
}

.mar0 {
    margin-right: 0px !important
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: none !important;
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: none !important;
}

.login-btn a:hover {
    color: #ffbf27 !important;
    background: none !important
}

.regi-btn a:hover {
    color: #ffbf27 !important;
    background: none !important
}

.nav>li>a:focus {
    background: none !important
}

.clrblue {
    color: #2994ca;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    text-align: center;
    white-space: nowrap;
}

.clrmaroon {
    color: #d61b69;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    text-align: center;
    white-space: nowrap;
}

.clrgreen {
    color: #80b144;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    text-align: center;
    white-space: nowrap;
}

.btnbg-right-bottom {
    position: absolute;
    right: 57px;
    bottom: 0;
}

.mar-right-30 {
    margin-right: 35px;
}


/*5thApril New Changes*/

.doctorspinkbg {
    width: 247px;
    height: 247px;
    display: block;
    border: 5px solid #c71d62;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px;
    padding: 0px;
    border-radius: 20px;
}

.doctorsbluebg img,
.doctorsgreenbg img,
.doctorsgraybg img,
.doctorspinkbg img,
.gallerydoctorsbluebg img,
.gallerydoctorsgreenbg img,
.gallerydoctorsgraybg img,
.gallerydoctorspinkbg img {
    border-radius: 14px;
}

.doctorsgraybg {
    width: 247px;
    height: 247px;
    display: block;
    border: 5px solid #797979;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px;
    padding: 0px;
    border-radius: 20px;
}

.doctorsgreenbg {
    width: 247px;
    height: 247px;
    display: block;
    border: 5px solid #84b24a;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px;
    padding: 0px;
    border-radius: 20px;
}

.doctorsbluebg {
    width: 247px;
    height: 247px;
    display: block;
    border: 5px solid #4aa2cd;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    border-radius: 20px;
}

.gallerydoctorsbluebg {
    width: 200px;
    height: 200px;
    display: block;
    border: 5px solid #4aa2cd;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    border-radius: 20px;
}

.gallerydoctorspinkbg {
    width: 200px;
    height: 200px;
    display: block;
    border: 5px solid #c71d62;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    border-radius: 20px;
}

.gallerydoctorsgreenbg {
    width: 200px;
    height: 200px;
    display: block;
    border: 5px solid #84b24a;
    box-shadow: 1px 3px 7px 2px #bbb;
    margin: 0px 10px 0px 0px;
    padding: 0px;
    border-radius: 20px;
}

.mleft15 {
    margin-left: 15px;
}

.mrgt25 {
    margin-right: 25px;
}


/*.hero {
    position: absolute;
    top: 93%;
    left: 67%;
    z-index: 3;
    color: #fff;
    text-align: center;
    -webkit-transform: translate3d(-50%,-50%,0);
    -moz-transform: translate3d(-50%,-50%,0);
    -ms-transform: translate3d(-50%,-50%,0);
    -o-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);*/


/* width: 90%; */


/*float: right;
    background: #fff;
    padding: 10px;
}

    .hero h1 {
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-family: 'cronus_roundregular';*/


/* top: -80%; */


/* left: 60%; */


/*z-index: 99;
        font-size: 28px;
        color: #002f97;
        top: 15px;
    }

hero h2 {
    font-weight: bold;
    -webkit-text-stroke-width: 10px;
    -webkit-text-stroke-color: #ff563d;
    position: absolute;
    top: -4px;*/


/* left: 60%; */


/*z-index: -1;
    font-size: 65px;
    font-family: 'cronus_roundregular';
}*/

.img-autostyle {
    margin: auto
}

.aboutsusbackground {
    background-image: url(mediaimages/aboutuspage.png);
    background-repeat: no-repeat;
    /* background-position: left center; */
    background-position: 541px -156px;
    width: auto;
    z-index: -1;
}


/*21st APril NEw Changes*/

.trainingbg {
    background-image: url("mediaimages/trainingbg.jpg");
    background-repeat: no-repeat;
    background-position: left right;
}

.training {
    margin: 0px;
    padding: 0px;
    text-align: left;
}

.training h1 {
    margin: 0px;
    padding: 30px 0;
    font-size: 42px;
    color: #011a8b;
    font-family: 'Fredoka One', cursive;
}

.training p {
    color: #070707;
    line-height: 24px;
}

.traininglist {
    margin: 0px;
    padding: 0px;    
}

.traininglist ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.traininglist li {
    margin: 0px;
    padding: 8px 0px 8px 0;
    list-style-type: none;
    list-style-position: inside;
    font-size: 16px;
}

.traininglist li img {
    margin: 0 10px 0 0;
}

.mtop14 {
    margin-top: 14%;
}

.welcomebg {
    background-image: url("mediaimages/welcomebg.jpg");
    background-repeat: no-repeat;
    background-position: left right;
}

.welcomtxt {
    margin: 0px;
    padding: 0px;
}

.welcomtxt h2 {
    margin: 35px 0px 0px 0px;
    padding: 10px 0;
    color: #2c2c2c;
    font-size: 42px;
    font-family: 'Fredoka One', cursive;
}

.welcomtxt p {
    color: #070707;
    line-height: 32px;
    text-align: justify;
}

.aboutbg h2 {
    background-color: transparent;
    padding: 10px;
    font-family: 'Fredoka One', cursive;
    font-size: 42px;
    color: #011a8b;
    text-align: left;
}

.aboutbg {
    background-color: #fdeee9;
    padding: 10px;
}

.aboutbglist {
    margin: 0px;
    padding: 0px;
    text-align: left;
}

.aboutbglist ul {
    margin: 0px;
    padding: 0px;
}

.aboutbglist li {
    margin: 0px;
    padding: 10px 0;
    color: #1d1d1d;
    list-style-type: none;
    list-style-position: inside;
    font-size: 16px;
}

.aboutbglist li img {
    margin: 8px 8px 8px 0;
    float: left;
}

.serbg h2{
    font-family: 'Fredoka One', cursive;
    font-size: 42px;
    color: #011a8b;
    margin: 50px auto;
}

.serbg h5 {
    font-family: 'cronus_roundregular';
    font-size: 22px;
    color: #000000;
    margin: 10px auto;
    font-weight: 700;
}

.activtybg {
    margin: 0px;
    background: #d61b69;
    padding: 20px;
    /* background-image: url(mediaimages/school-sketch-elements-4.png); */
    background-position: center top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.serviceBox2 {
    text-align: center;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 20px;
    transition: all .4s ease-in-out;
    height: 600px;
    min-height: 600px;
    overflow-y: scroll;
    box-shadow: 1px 1px 1px 5px #b8044f;
}

.blob {
    border-radius: 30% 70% 70% 30% / 30% 45% 55% 70%;
    transition: all 0.7s ease-in-out;
}

.blob:hover {
    border-radius: 57% 43% 31% 69% / 54% 61% 39% 46%;
    border: 10px solid #ffe293;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.serviceBox2 .title {
    font-size: 34px;
    text-align: center;
    color: #011a8b;
    font-family: 'dinokoregular';
    text-shadow: 1px 2px 1px #ccc;
    padding: 15px 0;
}

.serviceBox2 .lists {
    margin: 0px;
    padding: 0px;
}

.serviceBox2 .lists ul {
    margin: 0px;
    padding: 0px;
}

.serviceBox2 .lists li {
    margin: 0px 0px 0px 20px;
    padding: 5px 0px;
    text-align: left;
}

.single-footer-widget .social li a:hover,
.single-footer-widget .social li a:focus {
    background-color: #f5890d;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.social {
    padding-left: 0;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 0;
}

.social li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 40px;
    border-radius: 50%;
    background-color: #ea512e;
    color: #fff;
    text-align: center;
    font-size: 18px;
}

.social li {
    display: inline-block;
    margin-right: 10px;
}

.social li a:hover,
.social li a:focus {
    background-color: #f5890d;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.divscroll {
    margin: 0 auto;
    overflow: hidden !important;
}

.divscroll:hover {
    overflow-y: scroll !important;
}


/*Carousel*/


/*Intro Craousel*/

.part1 .owl-carousel .item {
    height: auto;
    background: #4DC7A0;
    color: #fff;
    position: relative;
}

.part1 .owl-carousel .item img {
    width: 100%;
}

.part1 .owl-item .item .text {
    width: 90%;
    height: inherit;
    position: absolute;
    opacity: 0;
    z-index: 99;
    top: 30%;
    left: 10%;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.6);
    text-align: center;
}

.part1 .owl-item .item .text:before {
    position: absolute;
    content: '';
    width: 5px;
    height: 125px;
    background: #0077B6;
    top: 20%;
    left: 0%;
}

.part1 .owl-item .item .text p {
    margin: 100% 0 0 0;
    transform: rotate(-5deg);
}

.part1 .owl-item.active .item .text {
    opacity: 1;
    transition: all 1s ease 0.8s;
    padding: 10px;
}

.part1 .owl-item.active .item .text p {
    margin: 10px auto;
    transform: rotate(0deg);
    transition: all 1s ease 0.6s;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

.part1 .owl-item .item .text h3 {
    font-size: 42px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    text-align: left;
}

.part1 .owl-item .item .text h3 span {
    color: #0077B6;
}

.part1 .owl-item .item .text .btn {
    width: 200px;
    height: 55px;
    border-radius: 40px;
    border: 2px solid #25AAE2;
    background: #25AAE2;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    transition: 1s ease;
}

.part1 .owl-item .item .text .btn:hover {
    color: #25AAE2;
    background-color: #fff;
    padding: 0px !important;
}

.part1 .owl-item .item .text .btn .fa {
    font-size: 24px;
}

.part1 .owl-item .item .text .btn :hover .fa {
    background-color: #fff;
    border-radius: 50%;
    color: #000;
}

.part1 .owl-carousel .item {
    background-color: transparent;
}

.part1 .maincaroimg {
    width: 100%;
}

.bannerh1Txt {
    margin: 1px 0px 0px 65px;
}

.banner2h1Txt {
    margin: 21px 0px 0px -10px;
}

.banner3h1Txt {
    margin: 0px 0px 0px 50px;
}

.hero {
    position: absolute;
    /* top: 0px; */
    left: 52%;
    z-index: 3;
    color: #fff;
    text-align: right;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -o-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    /* width: 90%; */
    float: right;
    /* background: #fff; */
    padding: 10px;
    letter-spacing: 3px;
    bottom: 18px;
}

.hero h1 {
    font-weight: bold;
    margin: 0;
    padding: 0;
    /* top: -80%; */
    /* left: 60%; */
    z-index: 99;
    font-size: 45px;
    color: #fff;
    letter-spacing: 3px;
    top: 25px;
    line-height: 44px;
    font-family: 'Fredoka One', cursive;
    
}


/*.carousel-control .left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
    background-repeat: repeat-x;
    background: none !important;
}

.carousel-control .right {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
    background: none !important;
}*/

.hero-bannertxt2 {
    left: 57%;
    /* right: -125px; */
    width: 60%;
}

.hero-bannertxt1 {
    left: 57%;
    /* right: -125px; */
    width: 60%;
}

.hero-bannertxt3 {
    left: 55%;
    /* right: -125px; */
    width: 60%;
}
.heading{
    font-family: 'Fredoka One', cursive;
    font-size: 42px;
    color: #011a8b;
}
.mar-20{
    margin-top:20px !important;
}