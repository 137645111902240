.preassessment-hg .ant-modal-content .ant-modal-body{
    height: calc(100vh - 197px) !important;
    overflow-y: auto !important;
}
.errorstyle {
    border: 1px solid red !important;
}
.form-fixheight>.ant-form-item-label>label::before
{
    padding-bottom:18px !important;
    padding-left:14px !important;
}
#addchild .ant-card-body{
    padding:5px !important;
}
.form-fixuploadheight>.ant-form-item-label>label::before{
    padding-bottom:18px !important;
    padding-left:29px !important;
}