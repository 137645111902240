.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}


.junior__classes__area,
junior__testimonial__area,
.junior__gallery__area,
.jnr__call__to__action,
.jnr__blog_area,
.junior__upcomming__area,
.bcare__subscribe,
.footer__wrapper {
  display: none;
}

.m-t-10 {
  margin-top: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-b-30 {
  padding: 30px 0;
}

footer.footer--2 {
  padding-top: 0;
}

.btn-common-prime {
  background: #ddd;
  border: 0;
  font-size: 16px;
  height: 37px;
  line-height: 35px;
  padding: 0 25px;
}

.btn-common-prime:hover,
.btn-common-prime:focus,
.btn-common-prime:active {
  color: #fff;
  background: #89d700;
}

.max-wid-600 {
  max-width: 600px;
}

.max-wid-700 {
  max-width: 700px;
}

.lblErrmsg {
  color: red;
}

.mainmenu__wrapper.bg__cat--1.poss-relative.header_top_line:after {
  background: none;
}

.bootstrap-datetimepicker-widget {
  display: block;
}

.header--one .sticky__header.is-sticky {
  background: #f4e416;
}

.bg--theme {
  background: #45b04e;
}

.btn-link {
  color: #45b04e;
  font-weight: 700;
}

.fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
  margin-right: 8px;
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

#btnLogIn,
#btnForget {
  min-width: 105px;
}

.loadingbtn {
  max-width: 105px;
  font-size: 14px;
  padding-left: 15px;
}

body {
  min-height: 100vh;
  position: relative;
  margin: 0;
}

.badge {
  padding: 0.45em 0.4em;
  font-size: 100%;
}

.header_top_line::before {
  background: none;
}

input[type=radio],
input[type=checkbox] {
  position: relative;
  margin-right: 10px;
}

input[type=radio]:before,
input[type=checkbox]:before {
  content: '';
  position: absolute;
  background: #fff;
  border: 3px solid #89d700;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: -2px;
  left: -2px;
}

input[type=radio]:checked:before,
input[type=checkbox]:checked:before {
  background-color: #0ba360;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  content: "";
  background-size: 18px 11px;
  background-position: center;
  background-repeat: no-repeat;
}

input[type=checkbox]+label,
input[type=radio]+label {
  font-size: 12px;
}

.Pageheadercontainer {
  box-shadow: 8px 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

#MenuView a.level1.popout {
  background: none;
}

#MenuView a {
  display: flex !important;
  white-space: initial !important;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#MenuView a:hover {
  color: #89d700;
}

.mainmenu__wrapper .mainmenu__wrap .mainmenu__nav .level1 {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mainmenu__wrapper .mainmenu__wrap .mainmenu__nav a.level1 {
  height: auto;
}

.mainmenu__wrapper .mainmenu__wrap .mainmenu__nav ul.level1>li {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 20px;
}

.DynaminMenu {
  background: #fff;
  box-shadow: 0 0px 7px 2px #eee;
}

.DynaminMenu li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.DynaminMenu li a {
  color: #333 !important;
  font-size: 13px !important;
  font-weight: 200 !important;
  line-height: 32px;
  padding: 6px 20px 6px 8px;
  text-transform: capitalize;
  transition: all 300ms ease 0s;
}

/*Telerik Grid css*/
.RadGrid_Vista .rgHeader,
.RadGrid_Vista th.rgResizeCol {
  background: #f9f9f9 !important;
  border: 1px solid #ddd !important;
  height: 35px;
}

.RadGrid_Vista .rgMasterTable,
.rgDetailTable {
  /*font-family: 'Montserrat', sans-serif !important;*/
  font-size: 14px !important;
}

.RadGrid_Vista .rgRow,
.RadGrid_Vista .rgAltRow {
  height: 35px !important;
}

.RadGrid_Vista .rgRow {
  background: #fff !important;
}

.RadGrid_Vista .rgAltRow {
  background: #f9f9f9 !important;
}

.RadGrid_Vista .rgPager {
  background: #f9f9f9 !important;
}

.RadGrid_Vista .rgFilterRow {
  background: #f9f9f9 !important;
}

.RadGrid_Vista .rgNumPart a.rgCurrentPage,
.RadGrid_Vista .rgNumPart a.rgCurrentPage:hover {
  background: none;
  border: 1px solid #3399FF;
}

.RadGrid.RadGrid_Vista {
  outline: none !important;
}

.RadGrid_Vista .rgPagerCell {
  background: #f9f9f9 !important;
}

.RadGrid_Vista .rgHeader {
  color: #000 !important;
  font-weight: bold !important;
  text-align: center !important;
  text-transform: uppercase;
}

.RadGrid_Vista .rgRow>td,
.RadGrid_Vista .rgAltRow>td {
  border-width: 0px 0px 1px 1px !important;
  border-color: #ddd !important;
}

.RadGrid_Vista tr>td {
  border: 1px solid #ddd !important;
}

.RadGrid_Vista .rgPagerCell .rgPagerButton,
.RadGrid_Vista .rgPagerCell .rgActionButton {
  border-radius: 50% !important;
}

.RadGrid_Vista .rgPagerCell .rgNumPart a {
  border-radius: 50% !important;
  padding: 4px 11px !important;
  border-color: transparent !important;
}

.RadGrid_Vista .rgPagerCell .rgNumPart a.rgCurrentPage {
  background: #1976d2 !important;
  color: #fff !important;
  border-color: #1976d2 !important;
}

.RadGrid_Vista .rgMasterTable,
.rgDetailTable {
  font-family: 'Proxima Nova', 'sans-serif' !important;
}

.RadGrid_Vista .rgGroupItem {
  border: 0 !important;
}

.RadGrid_Vista .rgPagerCell .rgNumPart a.rgCurrentPage {
  background: #60A512 !important;
  color: #fff !important;
  border-color: #60A512 !important;
}

.RadGrid .rgGroupHeaderText {
  color: #6F7273;
  font-size: 14px !important;
}

.RadGrid_Vista tbody .rgRow>td,
.RadGrid_Vista tbody .rgAltRow>td {
  font-size: 12px !important;
}

.RadGrid_Vista .rgMasterTable,
.RadGrid_Vista .rgDetailTable,
.RadTreeView_Vista,
.RadTreeView_Vista a.rtIn,
.RadTreeView_Vista .rtEdit .rtIn input {
  font-family: 'Montserrat', sans-serif !important;
}

.RadGrid_Vista {
  border-width: 0 1px 1px 1px !important;
  border-color: #ddd !important;
  border-style: solid !important;
}

.RadGrid_Vista.rg_small .rgMasterTable {
  font-size: 11px !important;
}

.RadGrid_Vista .rgHeaderDiv {
  margin-right: 0 !important;
}

.RadGrid_Vista .rgHeaderDiv {
  border: 0 !important;
}

.multiselect-container>li>a>label {
  padding: 3px 20px 3px 15px !important;
}

.courses .courses__inner .courses__wrap .courses__content h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.btn-group .multiselect-container.dropdown-menu {
  height: 150px;
  overflow: auto;
  transform: inherit !important;
}

/*footer.footer--2{
  position: absolute;
  width: 100%;
  bottom: 0;
}*/
.custom-file {
  display: inherit;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rptitems {
  padding: 15px 0;
  box-shadow: 0px 0 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  box-shadow: 0px -3px 8px 0px #ddd;
  font-weight: bold;
  color: #89d700;
}

.courses .courses__inner .courses__wrap {
  padding: 20px 20px;
}

.nav-tabs-forms {
  width: 100%;
}

.courses .courses__thumb {
  height: 185px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.rptitems_attatched,
.rptitems_attatched1 {
  padding: 7px;
}

.mean-container .mean-nav {
  background: #f4e416;
}

.rptlbl {
  cursor: pointer;
}

.rptlbl a:hover {
  color: #89d700 !important;
  text-decoration: underline !important;
}

.repeaterclass img {
  height: 45px;
  min-width: 25px;
}

.repeatertitleclass {
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-file label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rptitems_comments {
  border-top: 1px solid #ddd;
}

.rptitems_comments .divleft {
  border-right: 1px solid #ddd;
  padding: 5px 15px;
}

.rptitems_comments .divright {
  padding: 5px 15px;
}

.rptheader_comments {
  background: #f8fafb;
  padding: 10px 0;
}

#ModalCommentview .modal-body {
  padding: 0;
}

.modal-header {
  background: #89d700;
}

.modal-header h5 {
  color: #fff;
}

.modal-header button.close {
  opacity: 1;
}

.section-sld h3 {
  margin-bottom: 13px;
}

.section-sld p {
  line-height: 27px;
  margin: 8px 0;
}

.p-b-30 {
  padding-bottom: 30px;
}

html {
  position: relative;
  min-height: 100%;
  overflow-y: auto !important;
}

#header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: #fff;
}

.ContentBody {
  padding: 173px 0;
}

.repeaterclass.disable {
  opacity: 0.6;
}

.repeaterclass.disable .btn-fcupload,
.repeaterclass.disable .custom-file,
.repeaterclass.disable input[type="button"] {
  display: none;
}

/*Modify after Sk evaluate 10/sep */
.mainmenu__wrapper .logo,
.mainmenu__wrapper .mainmenu__wrap .mainmenu__nav .level1 {
  height: 85px;
}

.junior__header__top {
  height: 37px;
}

.mainmenu__wrapper {
  padding-top: 0;
}

.ContentBody {
  padding: 85px 0 120px;
}

/* .Pageheadercontainer {
  /*display: none;*/

.footer__contact__wrapper .single__footer__address .ft__contact__icon i {
  left: 38%;
  top: 38%;
  font-size: 20px;
}

.footer__contact__wrapper .single__footer__address .ft__contact__icon::before {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
}

.footer__contact__wrapper {
  padding: 15px 0;
}

.bg-none {
  background: none !important;
}

#li_Loggeduser .btn:focus,
#li_Loggeduser .btn {
  outline: none !important;
  box-shadow: none !important;
}

.accounting li+li::before {
  content: '';
}

.nav-pills .nav-link {
  padding: 10px 0 10px 23px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #45b04e;
}

.dropdown-item:active {
  background-color: #45b04e;
  color: #fff;
}

.shopbox {
  height: 85px;
}

.shopbox a {
  background: none !important;
  font-size: 14px;
  font-weight: 700;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #89d700;
}

.mean-container .mean-bar {
  float: left;
}

.nav-pills .nav-link>i {
  padding-right: 6px;
}

.loginmobilenav {
  padding: 16px;
  right: 0;
  position: absolute;
  z-index: 999;
}

.loginmobilenav li {
  float: left;
}

.loginmobilenav li a {
  font-size: 31px;
  color: #fff;
}

.cus_mainuserprofile button[type="button"] {
  color: #fff !important;
  font-size: 24px;
}

#header {
  background-color: #f8f8f8;
  border-color: #e7e7e7
}

.cus_mainuserprofile {
  margin-top: 10px;
}

.cus_bfrlinks li {
  margin-right: 16px;
}

.cus_bfrlinks a {
  font-size: 16px;
}

#loginbody .mean-container a.meanmenu-reveal {
  right: 15% !important;
}

.dropdown-menu-left {
  left: auto !important;
  right: -16px;
}

.modal-header h4 {
  color: #fff;
}

.gvSchdltime {
  border: 0;
}

.gvSchdltime th,
.gvSchdltime td {
  white-space: nowrap;
  font-size: 11px;
}

.pnlformheader {
  font-weight: bold;
  text-transform: uppercase;
}

.statuscard {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  padding: 15px;
  margin-left: 15px;
}

.rptitems_remedydocs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 6px;
}

.rptConsultremedyHeader {
  border-bottom: 2px solid #45b04e;
  padding-bottom: 10px;
  font-weight: 600;
}

#div_joinsesslink {
  background: #45b04e;
  color: #fff;
}

#linkjoin {
  font-weight: 600;
  font-size: 18px;
  text-decoration: underline;
  -webkit-animation: colorchange 4s infinite alternate;
  animation: colorchange 4s infinite alternate;
  -moz-animation: colorchange 4s infinite alternate;
}

.border-bottom-2-theme {
  border-bottom: 2px solid #45b04e;
}

.p-b-10 {
  padding-bottom: 10px;
}

.trainingtab_content .gvSchdltime th,
.gvSchdltime td {
  border: 0;
  padding: 0.25rem;
}

.rptschedulistheader {
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
  background: #f2f2f2;
}

.aspNetDisabled {
  opacity: 0.5;
  background: #ddd !important;
}

.btn-common-prime:hover,
.btn-common-prime:focus,
.btn-common-prime:active {
  color: #fff !important;
  background: #ddd !important;
}

.btn-common-prime {
  background: #89d700 !important;
  border: 0 !important;
  font-size: 16px !important;
  height: 37px !important;
  line-height: 35px !important;
  padding: 0 25px !important;
}

@-webkit-keyframes colorchange {
  0% {
    color: blue;
  }

  10% {
    color: #8e44ad;
  }

  20% {
    color: #1abc9c;
  }

  30% {
    color: #d35400;
  }

  40% {
    color: blue;
  }

  50% {
    color: #34495e;
  }

  60% {
    color: blue;
  }

  70% {
    color: #2980b9;
  }

  80% {
    color: #f1c40f;
  }

  90% {
    color: #2980b9;
  }

  100% {
    color: pink;
  }
}

/*.Maincontainer{
  margin-top:66px;
}
*/
/*Media Query*/
@media (min-width: 576px) {
  #loginbody .mean-container a.meanmenu-reveal {
    right: 21% !important;
  }

  #Mainmasterid .mean-container a.meanmenu-reveal {
    right: 0 !important;
  }
}

@media (min-width: 768px) {
  #Mainmasterid .mean-container a.meanmenu-reveal {
    right: 6% !important;
  }
}

@media (min-width: 992px) {
  #footer {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
  }

  .cus_mainuserprofile {
    margin-top: 18px;
  }
}


/**********/
@media (max-width: 991px) {
  .cus_UserdetailIcon {
    position: absolute;
    right: 0;
    width: auto;
    z-index: 999999;
    padding: 0;
  }
}

@media (max-width: 575px) {
  #loginbody .mean-container a.meanmenu-reveal {
    right: 22% !important;
  }

  #Mainmasterid .mean-container a.meanmenu-reveal {
    right: 18% !important;
  }
}

@media (max-width: 370px) {
  #loginbody .mean-container a.meanmenu-reveal {
    right: 34% !important;
  }
}

.custom-height-body {
  height: 100%;
  background-color: #e0e0dd;
  position: fixed;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* .ant-layout,.ant-layout-header{
background:transparent !important;
}
.ant-layout-header{
padding:0px !important;
height:130px !important;
line-height: 130px !important;
} */

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  /* -webkit-transition: border - color ease - in - out.15s, -webkit-box-shadow ease - in - out.15s;
  -o-transition: border - color ease - in - out.15s, box - shadow ease - in - out.15s;
  transition: border - color ease - in - out.15s, box - shadow ease - in - out.15s */
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #999
}

.form-control::-webkit-input-placeholder {
  color: #999
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled].form-control {
  background-color: #eee;
  opacity: 1
}

.form-control[disabled],
fieldset[disabled].form-control {
  cursor: not - allowed
}

textarea.form-control {
  height: auto
}

input[type=search] {
  -webkit-appearance: none
}

@media screen and(-webkit-min-device-pixel-ratio: 0) {

  input[type=date].form-control,
  input[type=time].form-control,
  input[type=datetime - local].form-control,
  input[type=month].form-control {
    line-height: 34px
  }

  .input-group-sm input[type=date],
  .input-group-sm input[type=time],
  .input-group-sm input[type=datetime - local],
  .input-group-sm input[type=month],
  input[type=date].input-sm,
  input[type=time].input-sm,
  input[type=datetime - local].input-sm,
  input[type=month].input-sm {
    line-height: 30px
  }

  .input-group-lg input[type=date],
  .input-group-lg input[type=time],
  .input-group-lg input[type=datetime - local],
  .input-group-lg input[type=month],
  input[type=date].input-lg,
  input[type=time].input-lg,
  input[type=datetime - local].input-lg,
  input[type=month].input-lg {
    line-height: 46px
  }
}

.form-group {
  margin-bottom: 15px
}

.body {
  font-family: 'maven_proregular', Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 28px;
  font-weight: 300;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media(min-width: 768px) {
  .container {
    width: 750px
  }
}

@media(min-width: 992px) {
  .container {
    width: 970px
  }
}

@media(min-width: 1200px) {
  .container {
    width: 1170px
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.row {
  margin-right: -15px;
  margin-left: -15px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left
}

.col-xs-12 {
  width: 100%
}

.col-xs-11 {
  width: 91.66666667%
}

.col-xs-10 {
  width: 83.33333333%
}

.col-xs-9 {
  width: 75%
}

.col-xs-8 {
  width: 66.66666667%
}

.col-xs-7 {
  width: 58.33333333%
}

.col-xs-6 {
  width: 50%
}

.col-xs-5 {
  width: 41.66666667%
}

.col-xs-4 {
  width: 33.33333333%
}

.col-xs-3 {
  width: 25%
}

.col-xs-2 {
  width: 16.66666667%
}

.col-xs-1 {
  width: 8.33333333%
}

.col- xs-pull-12 {
  right: 100%
}

.col-xs-pull-11 {
  right: 91.66666667%
}

.col-xs-pull-10 {
  right: 83.33333333%
}

.col-xs-pull-9 {
  right: 75%
}

.col-xs-pull-8 {
  right: 66.66666667%
}

.col-xs-pull-7 {
  right: 58.33333333%
}

.col-xs-pull-6 {
  right: 50%
}

.col-xs-pull-5 {
  right: 41.66666667%
}

.col-xs-pull-4 {
  right: 33.33333333%
}

.col-xs-pull-3 {
  right: 25%
}

.col-xs-pull-2 {
  right: 16.66666667%
}

.col-xs-pull-1 {
  right: 8.33333333%
}

.col-xs-pull-0 {
  right: auto
}

.col-xs-push-12 {
  left: 100%
}

.col-xs-push-11 {
  left: 91.66666667%
}

.col-xs-push-10 {
  left: 83.33333333%
}

.col-xs-push-9 {
  left: 75%
}

.col-xs-push-8 {
  left: 66.66666667%
}

.col-xs-push-7 {
  left: 58.33333333%
}

.col-xs-push-6 {
  left: 50%
}

.col-xs-push-5 {
  left: 41.66666667%
}

.col-xs-push-4 {
  left: 33.33333333%
}

.col-xs-push-3 {
  left: 25%
}

.col-xs-push-2 {
  left: 16.66666667%
}

.col-xs-push-1 {
  left: 8.33333333%
}

.col-xs-push-0 {
  left: auto
}

.col-xs-offset-12 {
  margin-left: 100%
}

.col-xs-offset-11 {
  margin-left: 91.66666667%
}

.col-xs-offset-10 {
  margin-left: 83.33333333%
}

.col-xs-offset-9 {
  margin-left: 75%
}

.col-xs-offset-8 {
  margin-left: 66.66666667%
}

.col-xs-offset-7 {
  margin-left: 58.33333333%
}

.col-xs-offset-6 {
  margin-left: 50%
}

.col-xs-offset-5 {
  margin-left: 41.66666667%
}

.col-xs-offset-4 {
  margin-left: 33.33333333%
}

.col-xs-offset-3 {
  margin-left: 25%
}

.col-xs-offset-2 {
  margin-left: 16.66666667%
}

.col-xs-offset-1 {
  margin-left: 8.33333333%
}

.col-xs-offset-0 {
  margin-left: 0
}

@media(min-width: 768px) {

  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left,
  }

  .col-sm-12 {
    width: 100%
  }

  .col-sm-11 {
    width: 91.66666667%
  }

  .col-sm-10 {
    width: 83.33333333%
  }

  .col-sm-9 {
    width: 75%
  }

  .col-sm-8 {
    width: 66.66666667%
  }

  .col-sm-7 {
    width: 58.33333333%
  }

  .col-sm-6 {
    width: 50%
  }

  .col-sm-5 {
    width: 41.66666667%
  }

  .col-sm-4 {
    width: 33.33333333%
  }

  .col-sm-3 {
    text-align: left;
    width: 25%
  }

  .col-sm-2 {
    width: 16.66666667%
  }

  .col-sm-1 {
    width: 8.33333333%
  }

  .col-sm-pull-12 {
    right: 100%
  }

  .col-sm-pull-11 {
    right: 91.66666667%
  }

  .col-sm-pull-10 {
    right: 83.33333333%
  }

  .col-sm-pull-9 {
    right: 75%
  }

  .col-sm-pull-8 {
    right: 66.66666667%
  }

  .col-sm-pull-7 {
    right: 58.33333333%
  }

  .col-sm-pull-6 {
    right: 50%
  }

  .col-sm-pull-5 {
    right: 41.66666667%
  }

  .col-sm-pull-4 {
    right: 33.33333333%
  }

  .col-sm-pull-3 {
    right: 25%
  }

  .col-sm-pull-2 {
    right: 16.66666667%
  }

  .col-sm-pull-1 {
    right: 8.33333333%
  }

  .col-sm-pull-0 {
    right: auto
  }

  .col-sm-push-12 {
    left: 100%
  }

  .col-sm-push-11 {
    left: 91.66666667%
  }

  .col-sm-push-10 {
    left: 83.33333333%
  }

  .col-sm-push-9 {
    left: 75%
  }

  .col-sm-push-8 {
    left: 66.66666667%
  }

  .col-sm-push-7 {
    left: 58.33333333%
  }

  .col-sm-push-6 {
    left: 50%
  }

  .col-sm-push-5 {
    left: 41.66666667%
  }

  .col-sm-push-4 {
    left: 33.33333333%
  }

  .col-sm-push-3 {
    left: 25%
  }

  .col-sm-push-2 {
    left: 16.66666667%
  }

  .col-sm-push-1 {
    left: 8.33333333%
  }

  .col-sm-push-0 {
    left: auto
  }

  .col-sm-offset-12 {
    margin-left: 100%
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%
  }

  .col-sm-offset-9 {
    margin-left: 75%
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%
  }

  .col-sm-offset-6 {
    margin-left: 50%
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%
  }

  .col-sm-offset-3 {
    margin-left: 25%
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%
  }

  .col-sm-offset-0 {
    margin-left: 0
  }
}

@media(min-width: 992px) {

  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left
  }

  .col-md-12 {
    width: 100%
  }

  .col-md-11 {
    width: 91.66666667%
  }

  .col-md-10 {
    width: 83.33333333%
  }

  .col-md-9 {
    width: 75%
  }

  .col-md-8 {
    width: 66.66666667%
  }

  .col-md-7 {
    width: 58.33333333%
  }

  .col-md-6 {
    width: 50%
  }

  .col-md-5 {
    width: 41.66666667%
  }

  .col-md-4 {
    width: 33.33333333%
  }

  .col-md-3 {
    width: 25%
  }

  .col-md-2 {
    width: 16.66666667%
  }

  .col-md-1 {
    width: 8.33333333%
  }

  .col-md-pull-12 {
    right: 100%
  }

  .col-md-pull-11 {
    right: 91.66666667%
  }

  .col-md-pull-10 {
    right: 83.33333333%
  }

  .col-md-pull-9 {
    right: 75%
  }

  .col-md-pull-8 {
    right: 66.66666667%
  }

  .col-md-pull-7 {
    right: 58.33333333%
  }

  .col-md-pull-6 {
    right: 50%
  }

  .col-md-pull-5 {
    right: 41.66666667%
  }

  .col-md-pull-4 {
    right: 33.33333333%
  }

  .col-md-pull-3 {
    right: 25%
  }

  .col-md-pull-2 {
    right: 16.66666667%
  }

  .col-md-pull-1 {
    right: 8.33333333%
  }

  .col-md-pull-0 {
    right: auto
  }

  .col-md-push-12 {
    left: 100%
  }

  .col-md-push-11 {
    left: 91.66666667%
  }

  .col-md-push-10 {
    left: 83.33333333%
  }

  .col-md-push-9 {
    left: 75%
  }

  .col-md-push-8 {
    left: 66.66666667%
  }

  .col-md-push-7 {
    left: 58.33333333%
  }

  .col-md-push-6 {
    left: 50%
  }

  .col-md-push-5 {
    left: 41.66666667%
  }

  .col-md-push-4 {
    left: 33.33333333%
  }

  .col-md-push-3 {
    left: 25%
  }

  .col-md-push-2 {
    left: 16.66666667%
  }

  .col-md-push-1 {
    left: 8.33333333%
  }

  .col-md-push-0 {
    left: auto
  }

  .col-md-offset-12 {
    margin-left: 100%
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%
  }

  .col-md-offset-9 {
    margin-left: 75%
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%
  }

  .col-md-offset-6 {
    margin-left: 50%
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%
  }

  .col-md-offset-3 {
    margin-left: 25%
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%
  }

  .col-md-offset-0 {
    margin-left: 0
  }
}

@media(min-width: 1200px) {

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left
  }

  .col-lg-12 {
    width: 100%
  }

  .col-lg-11 {
    width: 91.66666667%
  }

  .col-lg-10 {
    width: 83.33333333%
  }

  .col-lg-9 {
    width: 75%
  }

  .col-lg-8 {
    width: 66.66666667%
  }

  .col-lg-7 {
    width: 58.33333333%
  }

  .col-lg-6 {
    width: 50%
  }

  .col-lg-5 {
    width: 41.66666667%
  }

  .col-lg-4 {
    width: 33.33333333%
  }

  .col-lg-3 {
    width: 25%
  }

  .col-lg-2 {
    width: 16.66666667%
  }

  .col-lg-1 {
    width: 8.33333333%
  }

  .col-lg-pull-12 {
    right: 100%
  }

  .col-lg-pull-11 {
    right: 91.66666667%
  }

  .col-lg-pull-10 {
    right: 83.33333333%
  }

  .col-lg-pull-9 {
    right: 75%
  }

  .col-lg-pull-8 {
    right: 66.66666667%
  }

  .col-lg-pull-7 {
    right: 58.33333333%
  }

  .col-lg-pull-6 {
    right: 50%
  }

  .col-lg-pull-5 {
    right: 41.66666667%
  }

  .col-lg-pull-4 {
    right: 33.33333333%
  }

  .col-lg-pull-3 {
    right: 25%
  }

  .col-lg-pull-2 {
    right: 16.66666667%
  }

  .col-lg-pull-1 {
    right: 8.33333333%
  }

  .col-lg-pull-0 {
    right: auto
  }

  .col-lg-push-12 {
    left: 100%
  }

  .col-lg-push-11 {
    left: 91.66666667%
  }

  .col-lg-push-10 {
    left: 83.33333333%
  }

  .col-lg-push-9 {
    left: 75%
  }

  .col-lg-push-8 {
    left: 66.66666667%
  }

  .col-lg-push-7 {
    left: 58.33333333%
  }

  .col-lg-push-6 {
    left: 50%
  }

  .col-lg-push-5 {
    left: 41.66666667%
  }

  .col-lg-push-4 {
    left: 33.33333333%
  }

  .col-lg-push-3 {
    left: 25%
  }

  .col-lg-push-2 {
    left: 16.66666667%
  }

  .col-lg-push-1 {
    left: 8.33333333%
  }

  .col-lg-push-0 {
    left: auto
  }

  .col-lg-offset-12 {
    margin-left: 100%
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%
  }

  .col-lg-offset-9 {
    margin-left: 75%
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%
  }

  .col-lg-offset-6 {
    margin-left: 50%
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%
  }

  .col-lg-offset-3 {
    margin-left: 25%
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%
  }

  .col-lg-offset-0 {
    margin-left: 0
  }

  .btn-common-prime {
    background: #89d700;
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.ant-btn-primary {
  border-color: #8ed754 !important;
  background: #8ed754 !important;
}

.ant-btn-link {
  color: #8ed754 !important;
  font-size: 17px;
  line-height: 17px;
}

a>i {
  color: #8ed754 !important;
}

.ant-modal-header {
  background: #8ed754 !important;
}

.modal-custom-top {
  top: 40px !important;
}

.modal-hg-custom {
  height: calc(100vh - 255px);
  overflow-y: auto;
}

.ant-radio-button-wrapper:hover {
  color: #8ed754 !important;
}
.ant-radio-button-wrapper-checked:hover{
  color:#ffffff !important;
}
.ant-radio-button-checked {
  background: #8ed754 !important;
  border-color: #8ed754 !important;
}

button,
input,
select,
textarea,
label,
a,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Patrick Hand', cursive;
}

.ant-form-item-label>label {
  font-size: 18px !important;
}

.ant-input {
  font-size: 18px !important;
}

.ant-checkbox {
  top: -1em !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.consent-form .ant-modal-body {
  height: calc(100vh - 193px) !important;
  overflow-y: auto;
}

.gradient-text,
.termsservice .ant-card-body>h3 {
  color: #2f376a;
  font-weight: bold;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.termsservice .ant-card-body {
  padding: 10px 25px !important;
}

.ant-table {
  font-size: 17px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

/*drawer style*/
.ant-drawer-header {
  background: #8ed754 !important;
  ;
}

.ant-drawer-title {
  color: #fff !important;
}

.ant-drawer-body input {
  margin-bottom: 5px;
}

.ant-drawer-body {
  /* background:rgb(201, 207, 201) !important; */
  padding: 0px !important;
  /* overflow: hidden !important; */
}

.ant-drawer-body .ant-card .ant-card-bordered {
  width: 99% !important;
}

/* .ant-drawer .ant-drawer-content .ant-card-body{
  overflow:hidden;
  height:calc(100vh - 93px);
} */
/* .ant-drawer-close {
  border: 1px solid var(--popup-header-bgcolor) !important;
  color: var(--popup-header-forecolor) !important;
  padding: 10px !important;
  margin: 6px !important;
} */
#addchild .ant-card-actions {
  border-top: none !important;
}

#addchild .ant-card-body {
  height: calc(100vh - 117px);
  overflow-y: auto;
}

#addchild .ant-card-actions .ant-row .ant-col-14 {
  margin-left: 40%;
}

.ant-drawer-title,
.ant-modal-title {
  color: #fff !important;
  font-size: 19px !important;
  font-weight: bold !important;
}

#paymenttable>tr {
  font-size: 17px;
}

#paymnet-card .ant-card-head-title {
  font-weight: bold;
  font-size: 17px;
}

.p-0 {
  padding: 0px !important;
}

.ant-spin-nested-loading>div>.ant-spin {
  color: #28be8a !important;
}

.p-r-7 {
  padding-right: 7px !important;
}

.logo-height {
  height: 126px !important;
  width: 84px;
  max-width: none !important;
}