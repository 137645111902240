@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes zoom-out-in {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .img-zoomin{
    animation: zoom-in-zoom-out 1s ease infinite ;
  }
.img-zoomout{
    animation: zoom-out-in 1s ease infinite ;

}