/*Menu Navigation Bar*/
.navbar {
	background-color: #fff;
	margin: 0px;
	border: 0;
	border-radius: 0;
	z-index: 199;
	/*box-shadow: 0px 10px 10px 0px rgba(239,213,193,0.6);*/
	font-family: 'cronus_roundregular';
}

.navbrd {
	height: 100px;
	position: relative;
	margin-left: 20px;
}

.navbar ul {
	margin: 8px 0px 0px 0px;
	padding: 0px;
	text-align: right;
}

	.navbar ul li {
		list-style-type: none;
		display: inline-block;
		position: relative;
		padding: 17px 14px;
		font-family: 'cronus_roundregular';
		margin: 0 15px;
	}

.navbar .nav-item {
	display: inline-block;
	position: relative;
	font-size: 24px;
	text-align: center;
	text-decoration: none;
	transition-delay: 0.5s;
	transition: all 0.5s ease;
}

.homebtnpad {
	padding: 0 20px;
}
.dashbrdbtnpad {
	padding: 0 8px;
}
.abtbtnpad {
	padding: 0 8px;
}
.ourteampad {
	padding: 0 8px;
}
.ourteampad {
	padding: 0 8px;
}

.servicesbtnpad {
	padding: 0 8px;
}

.contactbtnpad {
	padding: 0 14px;
}
/*.navbar .nav-item:before {
	position: absolute;
	content: '';
	background: #86c83e;
	bottom: -15px;
	width: 100%;
	height: 2.5px;
	transform: scale(0);
	transition-delay: 0.5s;
	transition: all 0.5s ease;
}*/
.navbar .current .nav-item:before {
	transform: scale(1);
}

.navbar .current .nav-item {
	color: #000;
}

.navbar li:hover .nav-item:before {
	transform: scale(1);
}

.navbar li:hover .nav-item {
	color: #000 !important;
}

.navbar-default .navbar-toggle {
	border-color: #fff;
	background: #9ee73b;
}

	.navbar-default .navbar-toggle .icon-bar {
		background-color: #fff;
	}

.navbar-default .navbar-collapse {
	border-color: #fff;
}

.navbar-default .navbar-toggle:hover .icon-bar {
	background-color: #9ee73b;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
	background-color: #fff !important;
}

.sticky {
	position: sticky;
	top: 0;
}




/*Dropdown*/
.dropdown {
	position: relative !important;
}

.dropdown-content {
	opacity: 0;
	position: absolute;
	background-color: #fff;
	min-width: 200px;
	height: 0%;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 9999;
	top: 50px;
	left: 0px;
	padding: 0px 20px;
	transition-delay: 0.5s;
	transition: all 0.5s ease;
	z-index: -999999;
	visibility: hidden;
}

	.dropdown-content ul {
		padding: 0px;
		margin: 0px;
	}

	.dropdown-content li {
		width: 100% !important;
		text-align: left;
		border-bottom: 1px solid #c3c3c3;
	}

	.dropdown-content a {
		color: #25AAE2;
		padding: 10px 10px;
		text-decoration: none;
		display: block;
	}

.dropdown:hover .dropdown-content {
	opacity: 1;
	visibility: visible;
	height: inherit;
}

.dropdown-content ul li {
	position: relative;
	border-bottom: 1px solid #c3c3c3;
}

.dropdown-content .fa {
	position: absolute;
	top: 10px;
	right: -5px;
	color: #25AAE2;
	padding: 5px;
}

/*Dropdown*/
/*Sub Dropdown*/

.sub-drop-menu {
	opacity: 0;
	position: absolute;
	background-color: #fff;
	min-width: 250px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 9999;
	top: 0px;
	left: 190px;
	padding: 10px 20px;
	transition-delay: 0.5s;
	transition: all 0.5s ease;
	z-index: -999999;
	visibility: hidden;
}

	.sub-drop-menu ul {
		padding: 0px;
		margin: 0px;
	}

	.sub-drop-menu li {
		width: 100% !important;
		text-align: left;
		border-bottom: 1px solid #c3c3c3;
	}

	.sub-drop-menu a {
		color: #25AAE2;
		padding: 10px 10px;
		text-decoration: none;
		display: block;
	}

.dropdown-content li:hover .sub-drop-menu {
	opacity: 1;
	visibility: visible;
}

.sub-drop-menu ul li {
	position: relative;
	border-bottom: 1px solid #c3c3c3;
}
/*Subdropdown*/

.homebtn {
	/*background: #ffbf27;*/
	color: #b917b9 !important;
}

/* Sweep To Top */
.hvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.hvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}



.abtbtn {
	padding: 5px 10px;
	color: #3636c1 !important;
}

	.abtbtn a {
		padding: 5px 10px;
		color: #3636c1 !important;
	}

/* Sweep To Top */
.abthvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.abthvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.abthvr-sweep-to-top:hover, .abthvr-sweep-to-top:focus, .abthvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.abthvr-sweep-to-top:hover:before, .abthvr-sweep-to-top:focus:before, .abthvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}


.servicesbtn {
	padding: 5px 10px;
	color: #e6e645 !important;
}

	.servicesbtn a {
		padding: 5px 10px;
		color: #e6e645 !important;
	}

/* Sweep To Top */
.serviceshvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.serviceshvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.serviceshvr-sweep-to-top:hover, .serviceshvr-sweep-to-top:focus, .serviceshvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.serviceshvr-sweep-to-top:hover:before, .serviceshvr-sweep-to-top:focus:before, .serviceshvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}



.gallerybtn {
	padding: 5px 10px;
	color: orange !important;
}

	.gallerybtn a {
		padding: 5px 10px;
		color: orange !important;
	}

.contactbtn {
	padding: 5px 10px;
}
/* Sweep To Top */
.galleryhvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.galleryhvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.galleryhvr-sweep-to-top:hover, .galleryhvr-sweep-to-top:focus, .galleryshvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.galleryhvr-sweep-to-top:hover:before, .galleryhvr-sweep-to-top:focus:before, .galleryhvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}




.contactbtn {
	padding: 5px 10px;
	color: #d00909 !important;
}

	.contactbtn a {
		padding: 5px 10px;
		color: #d00909 !important;
	}
/* Sweep To Top */
.contacthvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.contacthvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.contacthvr-sweep-to-top:hover, .contacthvr-sweep-to-top:focus, .contacthvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.contacthvr-sweep-to-top:hover:before, .contacthvr-sweep-to-top:focus:before, .contacthvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}


.tabs-left > .nav-tabs {
	border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
	display: none;
}

.tab-content > .active,
.pill-content > .active {
	display: block;
}

.tabs-left > .nav-tabs > li {
	float: none;
}

	.tabs-left > .nav-tabs > li > a {
		min-width: 74px;
		margin-right: 0;
		margin-bottom: 3px;
		color: #fff;
	}

.tabs-left > .nav-tabs {
	float: left;
	margin-right: 19px;
	border-right: 1px solid #ddd;
}

	.tabs-left > .nav-tabs > li > a {
		margin-right: 0px;
		-webkit-border-radius: 4px 0 0 4px;
		-moz-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
	}

		.tabs-left > .nav-tabs > li > a:hover,
		.tabs-left > .nav-tabs > li > a:focus {
			border-color: #eeeeee #dddddd #eeeeee #eeeeee;
			text-decoration: underline;
		}

	.tabs-left > .nav-tabs .active > a,
	.tabs-left > .nav-tabs .active > a:hover,
	.tabs-left > .nav-tabs .active > a:focus {
		border-color: #ddd transparent #ddd #ddd;
		*border-right-color: #ffffff;
	}

.managentab {
	background: #bd80cb;
	padding: 10px;
	border-radius: 10px 0 0px 10px;
	color: #fff;
	font-size: 20px;
}

.paneltab {
	background: #e78080;
	padding: 10px;
	border-radius: 10px 0 0px 10px;
	color: #fff;
	font-size: 20px;
}

.techtab {
	background: #85cbdf;
	padding: 10px;
	border-radius: 10px 0 0px 10px;
	color: #fff;
	font-size: 20px;
}

.specialtab {
	background: #8b8e90;
	padding: 10px;
	border-radius: 10px 0 0px 10px;
	color: #fff;
	font-size: 20px;
}

.nav > li > a:focus, .nav > li > a:hover {
	text-decoration: none;
	background-color: transparent;
	border: none;
}


.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
	color: #fff;
	cursor: pointer;
	background-color: transparent !important;
	border: none !important;
	border-bottom-color: transparent;
	text-decoration: underline;
}

.nav-tabs > li {
	float: left;
	margin-bottom: 10px;
}

	.nav-tabs > li > a {
		margin-right: 2px;
		line-height: 1.42857143;
		border: none !important;
		border-radius: 4px 4px 0 0;
	}

.tab-content {
	background: #fef8f6;
	min-height: 500px;
	height: 500px;
	overflow-y: scroll;
	padding: 10px 10px 10px 30px;
	/* margin-left: -25px; */
	position: relative;
	left: -20px;
	box-shadow: 3px 5px 7px 0px #edd7d0;
}

	.tab-content h4 {
		font-size: 23px;
		color: #bd80cb;
		font-weight: 700;
	}

.heaclor {
	color: #85cbdf !important;
}

.panelclor {
	color: #e78080 !important
}

.tab-content .title {
	font-size: 20px;
	font-family: 'maven_proregular';
	color: #bd80cb;
	font-weight: 700;
}

.tab-content .title1 {
	font-size: 20px;
	font-family: 'maven_proregular';
	color: #e78080;
	font-weight: 700;
}

.mtop4 {
	margin-top: 4%;
}

.fntsm {
	font-size: 14px;
	font-style: italic;
}

.tab-pane .tablist {
	margin: 0px;
	padding: 0px;
}

	.tab-pane .tablist ul {
		margin: 0px;
		padding: 0px;
	}

	.tab-pane .tablist li {
		margin: 0px 0px 0px 21px;
		padding: 0px 0px 7px 0px;
		x: 18px;
		color: #000;
	}
.dashbrdhvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.dashbrdhvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.dashbrdhvr-sweep-to-top:hover, .dashbrdhvr-sweep-to-top:focus, .dashbrdhvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.dashbrdhvr-sweep-to-top:hover:before, .dashbrdhvr-sweep-to-top:focus:before, .dashbrdhvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}
.ourteamhvr-sweep-to-top {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 6px;
}

	.ourteamhvr-sweep-to-top:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.ourteamhvr-sweep-to-top:hover, .ourteamhvr-sweep-to-top:focus, .ourteamhvr-sweep-to-top:active {
		color: white;
		border-radius: 6px;
	}

		.ourteamhvr-sweep-to-top:hover:before, .ourteamhvr-sweep-to-top:focus:before, .ourteamhvr-sweep-to-top:active:before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
			border-radius: 6px;
		}

.dashbrdbtn {
	padding: 5px 10px;
	color: indigo !important;
}

	.dashbrdbtn a {
		padding: 5px 10px;
		color: indigo !important;
	}
.ourteambtn {
	padding: 5px 10px;
	color: green !important;
}

	.ourteambtn a {
		padding: 5px 10px;
		color: green !important;
	}