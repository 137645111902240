.text-center {
    font-family: 'maven_proregular';
    font-size: 18px;
    color: #000;
    line-height: 28px;
    font-weight: 400;
}
.header-divider {
    background: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
    height: 5px;
}