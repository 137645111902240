.register-form .ant-card-actions{
    border-top:none !important;
   }
.register-form .ant-card-body{
padding:0px !important
   }
.paymentnote
{
   float: left;
   margin-bottom: 0px !important;
   margin-top: 28px;
   margin-left: 10px
}